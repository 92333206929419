import { Response } from 'interface';
import { authToken, defaultLang } from './storage';
import { toast } from 'react-toastify';

function generateHeader(object: any = {}): any {
    const header: { [k: string]: any } = {};
    header['language'] = defaultLang.get();
    if (authToken.get()) {
        header['Authorization'] = 'Bearer ' + authToken.get()?.accessToken;
    }
    for (const key of Object.keys(object)) {
        header[key] = object[key];
    }
    return header;
}
export function del<R>(url: string): Promise<Response<R>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'DELETE',
            headers: generateHeader({ 'Content-Type': 'application/json' }),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ value: data, status });
                else {
                    toast.error(data.message);
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// post request
export function post<R>(url: string, body: any): Promise<Response<R>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: generateHeader({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(body),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ value: data, status });
                else {
                    toast.error(data.message);
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// form request (not post, like html form submit)
export function form<R>(url: string, body: any): Promise<Response<R>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            body: body,
            headers: generateHeader(),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ value: data, status });
                else {
                    toast.error(data.message);
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// put request
export function put<R>(url: string, body: any): Promise<Response<R>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: generateHeader({ 'Content-Type': 'application/json' }),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ value: data, status });
                else {
                    toast.error(data.message);
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// patch request
export function patch<R>(url: string, body: any): Promise<Response<R>> {
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: generateHeader({ 'Content-Type': 'application/json' }),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ value: data, status });
                else {
                    toast.error(data.message);
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

// get request
export function get<R>(url: string, params: { [k: string]: any } = {}): Promise<Response<R>> {
    const generatedUrl = new URL(url);
    // add query parameters like filters or pagination parameters
    Object.keys(params).forEach((key) => {
        generatedUrl.searchParams.append(key, params[key]);
    });
    let status: number;
    return new Promise((resolve, reject) => {
        fetch(generatedUrl.href, {
            method: 'GET',
            headers: generateHeader({ 'Content-Type': 'application/json' }),
        })
            .then(function (response) {
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                if (responseValidator(status)) resolve({ value: data, status });
                else {
                    toast.error(data.message);
                    reject({ data, status });
                }
            })
            .catch((/* err */) => {
                reject({ data: null, status });
            });
    });
}

function responseValidator(status: number): boolean {
    // switch (status) {
    //     case 401:
    //         if (defaultLang.get() === Language.en) toast.error('Unauthorized user');
    //         if (defaultLang.get() === Language.fa) toast.error('وارد حساب کاربری خود نشده‌اید');
    //         break;
    //     case 403:
    //         if (defaultLang.get() === Language.en) toast.error('Forbidden');
    //         if (defaultLang.get() === Language.fa) toast.error('دسترسی غیر‌مجاز');
    //         break;
    //     case 404:
    //         if (defaultLang.get() === Language.en) toast.error('Not Found');
    //         if (defaultLang.get() === Language.fa) toast.error('درخواست مورد نظر موجود نمی‌باشد');
    //         break;
    //     default:
    //         if (status < 200 && status >= 300) {
    //             if (defaultLang.get() === Language.en) toast.error('Something went wrong');
    //             if (defaultLang.get() === Language.fa) toast.error('خطایی رخ داده است');
    //         }
    // }

    return status >= 200 && status < 300;
}

export function upload<R>(
    URL: string,
    formData: any,
    auth = true,
    onProgress?: (progress: number) => void,
): Promise<Response<R>> {
    // let abort: any;
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        // abort = request.abort;
        request.upload.addEventListener('progress', function (e) {
            if (onProgress) onProgress(e.loaded);
        });
        request.open('post', URL);
        request.onload = function () {
            if (request.readyState == XMLHttpRequest.DONE)
                resolve({ status: request.status, value: JSON.parse(request.responseText) });
            else reject({ status: request.status, value: null });
        };
        request.onerror = () => reject({ status: request.status, data: null });
        if (auth) request.setRequestHeader('Authorization', 'Bearer ' + authToken.get()?.accessToken);
        request.timeout = 45000;
        request.send(formData);
    });
    // return { promise, abort };
}

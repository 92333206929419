import React, { useState, useEffect } from 'react';
import { AuthStatus, Language, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './mobileNavbar.style.scss';
import { headerNavbarItems } from '../navbar.data';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { NFSPath } from 'data/nfs.path';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import Popover from '@mui/material/Popover';
import changeLang from 'scripts/changeLang';
import Iran from 'assets/images/Countries/iran.svg';
import USA from 'assets/images/Countries/usa.svg';
import { Button } from 'utilities/components';
import { FaInstagram, FaTelegram, FaYoutube } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { PulseLoader } from 'react-spinners';
import { ButtonTypes } from 'utilities/components/button/button.index';
import { setAuth, setHeaderLogin, setUserData } from 'redux/actions';
import { authToken } from 'scripts/storage';

const HeaderMobileNavbar: React.FC<ConnectedProps<typeof connector> & { active: boolean; onClose: () => void }> =
    function (props: ConnectedProps<typeof connector> & { active: boolean; onClose: () => void }) {
        const history = useHistory();
        const location = useLocation();
        const [hash, setHash] = useState(location.hash);
        const [pathName, setPathName] = useState(location.pathname);
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
        const [anchorElAcc, setAnchorElAcc] = useState<HTMLButtonElement | null>(null);

        useEffect(() => {
            setPathName(location.pathname);
            setHash(location.hash);
        }, [history, location]);

        function logoutHandler() {
            props.dispatch(setAuth(AuthStatus.inValid));
            props.dispatch(setUserData(null));
            authToken.remove();
        }

        const handleClickAcc = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorElAcc(event.currentTarget);
        };

        const handleCloseAcc = () => {
            setAnchorElAcc(null);
        };
        const openAcc = Boolean(anchorElAcc);
        const idAcc = openAcc ? 'simple-popoverAcc' : undefined;

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const loginClickHandler = () => {
            props.onClose();
            props.dispatch(setHeaderLogin(true));
        };

        const handleClose = () => {
            setAnchorEl(null);
        };
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        // Update the hash when the location changes
        useEffect(() => {
            setHash(location.hash);
        }, [history, location]);

        return (
            <>
                <Popover
                    id={idAcc}
                    open={openAcc}
                    anchorEl={anchorElAcc}
                    onClose={handleCloseAcc}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div className="change-lang-popover account">
                        <div className="info">
                            <label>{props.language.header.yourEmail}</label>
                            <p>{props.user?.email}</p>
                        </div>
                        <div
                            onClick={() => {
                                logoutHandler();
                                handleCloseAcc();
                                props.onClose();
                            }}
                            className={`item`}
                        >
                            <label>{props.language.header.logout}</label>
                        </div>
                    </div>
                </Popover>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className="change-lang-popover">
                        <div
                            onClick={() => {
                                changeLang(Language.en, props.dispatch);
                                handleClose();
                            }}
                            className={`item ${props.language.code === Language.en ? 'selected' : ''}`}
                        >
                            <label>English</label>
                            <img src={USA} alt="USA" />
                        </div>
                        <div
                            onClick={() => {
                                changeLang(Language.fa, props.dispatch);
                                handleClose();
                            }}
                            className={`item ${props.language.code === Language.fa ? 'selected' : ''}`}
                        >
                            <label>فارسی</label>
                            <img src={Iran} alt="Iran" />
                        </div>
                    </div>
                </Popover>
                <div
                    onClick={() => props.onClose()}
                    className={`nfs-header-mobile-navbar-backdrop ${props.active ? 'active' : ''}`}
                ></div>
                <div
                    className={`nfs-header-mobile-navbar-container ${props.active ? 'active' : ''} ${props.language.code === Language.fa ? 'fa' : 'en'}`}
                >
                    <div className="nfs-header-mobile-navbar-container-content">
                        <div className="header">
                            {props.language.code === Language.en ? (
                                <IoMdArrowBack
                                    className="back-icon"
                                    size={24}
                                    color="#ffffff"
                                    onClick={() => props.onClose()}
                                />
                            ) : (
                                <IoMdArrowForward
                                    className="back-icon"
                                    size={24}
                                    color="#ffffff"
                                    onClick={() => props.onClose()}
                                />
                            )}
                            <span className="spacer" />

                            <button aria-describedby={id} onClick={handleClick} className="change-lang-container">
                                <img src={props.language.code === Language.en ? USA : Iran} alt="Iran" />
                            </button>
                        </div>
                        <div className="items-container">
                            {headerNavbarItems(props.language).map((item, index) =>
                                item.key.includes('#') ? (
                                    pathName !== '/' ? (
                                        <Link onClick={() => props.onClose()} key={index} to={`/${item.key}`}>
                                            {item.text}
                                        </Link>
                                    ) : (
                                        <a onClick={() => props.onClose()} key={index} href={`${item.key}`}>
                                            {item.text}
                                        </a>
                                    )
                                ) : (
                                    <Link
                                        onClick={() => props.onClose()}
                                        to={item.key}
                                        className={`content ${pathName === item.key ? 'active' : ''}`}
                                        key={index}
                                    >
                                        {item.text}
                                    </Link>
                                ),
                            )}
                        </div>
                        <span className="spacer" />
                        {props.isAuth === AuthStatus.valid && !!props.user ? (
                            <Button className="register-btn" onClick={handleClickAcc} type={ButtonTypes.secondary}>
                                {props.language.header.accountBtn}
                            </Button>
                        ) : props.isAuth === AuthStatus.inValid ? (
                            <Button className="register-btn" onClick={loginClickHandler}>
                                {props.language.header.registerBtn}
                            </Button>
                        ) : (
                            <Button className="register-btn">
                                <PulseLoader color="#ffffff" size={8} />
                            </Button>
                        )}
                        <div className="contact-us">
                            <h4>{props.language.footer.contactUsTitle}</h4>
                            <p>
                                <FaTelegram />
                                NFSSupport
                            </p>
                            <p>{props.language.footer.contactUsDescription}</p>
                        </div>
                        <div className="socials">
                            <Link target="_blank" to="#">
                                <FaSquareXTwitter />
                            </Link>
                            <Link target="_blank" to="#">
                                <FaInstagram />
                            </Link>
                            <Link target="_blank" to="#">
                                <FaYoutube />
                            </Link>
                            <Link target="_blank" to="#">
                                <FaTelegram />
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    };

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(HeaderMobileNavbar);

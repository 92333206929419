export const footerLang = {
    description:
        'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم استلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
    copyRight: 'تمامی حقوق برای هومینو محفوظ هست',
    listTitleOne: 'دسترسی سریع',
    listOneItemOne: 'تبدیل',
    listOneItemTwo: 'واریز',
    listOneItemThree: 'برداشت',
    listOneItemFour: 'پروفایل',
    listTitleTwo: 'دسترسی سریع',
    listTwoItemOne: 'تبدیل',
    listTwoItemTwo: 'واریز',
    listTwoItemThree: 'برداشت',
    listTwoItemFour: 'پروفایل',
    contactUsTitle: 'راه‌های ارتباطی',
    contactUsDescription: 'هر روز هفته ساعت ۸ تا ۲۴ پاسخگوی شما هستیم.',
};

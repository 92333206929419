export const voucherPageLang = {
    back: 'بازگشت',
    title: 'تبدیل ارز ووچر به ترون',
    description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و زنامه و مجله دکه لازم است و ز',
    addToWallet: 'افزودن به کیف پول‌های منتخب',
    savedWallets: 'کیف پول های منتخب',
    addWalletModal: {
        title: 'عنوان کیف پولتون رو وارد کنید',
        inputs: {
            label: 'عنوان کیف پول را وارد کنید',
        },
        button: 'ذخیره ی آدرس کیف پول',
        successToast: 'آدرس کیف پول با موفقیت اضافه شد'
    },
    empty: 'کیف پول منتخبی وجود ندارد',
    button: 'تبدیل ووچر به ترون',
    successToast: 'عملیات با موفقیت انجام شد',
    regexError: 'آدرس وارد شده اشتباه است',
    inputs: {
        voucher: 'کد ووچر',
        voucherActivation: 'کد فعالساز',
        wallet: 'آدرس کیف پول'
    }
};

import { headerLang } from 'components/header/header.lang';
import { Language } from 'interface';
import { landingLang } from './landing/landing.lang';
import { footerLang } from 'components/footer/footer.lang';
import { coinsLang } from 'data/coins.lang';
import { authenticationLang } from 'components/authentication/authentication.lang';
import { historyLang } from './history/history.lang';

const digit = (s: string | number) => s.toString().replace(/\d/g, (d) => '۰۱۲۳۴۵۶۷۸۹'[Number(d)]);

export const NFSLanguage = {
    general: {
        error: 'خطایی رخ داده است',
    },
    digit,
    code: Language.fa,
    header: headerLang,
    landing: landingLang,
    footer: footerLang,
    coins: coinsLang,
    authentication: authenticationLang,
    history: historyLang,
};

import React from 'react';
import { Provider } from 'react-redux';
import 'utilities/styles/index.scss';
import NFS from 'nfs/nfs.index';
import store from './redux/store';

function App() {
    return (
        <Provider store={store}>
            <NFS />
        </Provider>
    );
}

export default App;

import React, { useEffect, useState } from 'react';
import { AuthStatus, Language, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './header.style.scss';
import changeLang from 'scripts/changeLang';
import Logo from 'assets/images/nfslogo.png';
import Button, { ButtonTypes } from 'utilities/components/button/button.index';
import Iran from 'assets/images/Countries/iran.svg';
import USA from 'assets/images/Countries/usa.svg';
import Popover from '@mui/material/Popover';
import HeaderNavbar from './navbar/navbar.index';
import HeaderMobileNavbar from './navbar/mobile/mobileNavbar.index';
import { IoMenu } from 'react-icons/io5';
import { PulseLoader } from 'react-spinners';
import { setAuth, setHeaderLogin, setUserData } from 'redux/actions';
import { authToken } from 'scripts/storage';
import { NFSPath } from 'data/nfs.path';
import { useHistory } from 'react-router-dom';

const Header: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const history = useHistory();
    const [isMobileSidebarActive, setIsMobileSidebarActive] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [anchorElAcc, setAnchorElAcc] = useState<HTMLButtonElement | null>(null);

    const handleClickAcc = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElAcc(event.currentTarget);
    };

    const handleCloseAcc = () => {
        setAnchorElAcc(null);
    };
    const openAcc = Boolean(anchorElAcc);
    const idAcc = openAcc ? 'simple-popoverAcc' : undefined;

    const loginClickHandler = () => {
        props.dispatch(setHeaderLogin(true));
    };

    function logoutHandler() {
        props.dispatch(setAuth(AuthStatus.inValid));
        props.dispatch(setUserData(null));
        authToken.remove();
    }

    useEffect(() => {
        document.body.style.overflow = !isMobileSidebarActive ? 'auto' : 'hidden';
    }, [isMobileSidebarActive]);
    return (
        <>
            <HeaderMobileNavbar onClose={() => setIsMobileSidebarActive(false)} active={isMobileSidebarActive} />
            <div className="nfs-header">
                <div className="container">
                    <div className="right-section">
                        <IoMenu
                            onClick={() => setIsMobileSidebarActive(true)}
                            size={24}
                            color="#ffffff"
                            className="mobile-menu"
                        />
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div className="change-lang-popover mobile">
                                <div
                                    onClick={() => {
                                        changeLang(Language.en, props.dispatch);
                                        handleClose();
                                    }}
                                    className={`item ${props.language.code === Language.en ? 'selected' : ''}`}
                                >
                                    <label>English</label>
                                    <img src={USA} alt="USA" />
                                </div>
                                <div
                                    onClick={() => {
                                        changeLang(Language.fa, props.dispatch);
                                        handleClose();
                                    }}
                                    className={`item ${props.language.code === Language.fa ? 'selected' : ''}`}
                                >
                                    <label>فارسی</label>
                                    <img src={Iran} alt="Iran" />
                                </div>
                            </div>
                        </Popover>
                        <Popover
                            id={idAcc}
                            open={openAcc}
                            anchorEl={anchorElAcc}
                            onClose={handleCloseAcc}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div className="change-lang-popover account">
                                <div className="info">
                                    <label>{props.language.header.yourEmail}</label>
                                    <p>{props.user?.email}</p>
                                </div>
                                <div
                                    onClick={() => {
                                        logoutHandler();
                                        handleCloseAcc();
                                    }}
                                    className={`item`}
                                >
                                    <label>{props.language.header.logout}</label>
                                </div>
                            </div>
                        </Popover>
                        {props.isAuth === AuthStatus.valid && !!props.user ? (
                            <Button className="register-btn" onClick={handleClickAcc} type={ButtonTypes.secondary}>
                                {props.language.header.accountBtn}
                            </Button>
                        ) : props.isAuth === AuthStatus.inValid ? (
                            <Button className="register-btn" onClick={loginClickHandler}>
                                {props.language.header.registerBtn}
                            </Button>
                        ) : (
                            <Button className="register-btn">
                                <PulseLoader color="#ffffff" size={8} />
                            </Button>
                        )}
                        <button aria-describedby={id} onClick={handleClick} className="change-lang-container">
                            <img src={props.language.code === Language.en ? USA : Iran} alt="Iran" />
                        </button>
                    </div>
                    <HeaderNavbar />
                    <span className="space" />
                    <img onClick={() => history.push(NFSPath.landing)} className="logo" src={Logo} alt="logo" />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(Header);

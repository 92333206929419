import React, { useEffect, useRef, useState } from 'react';
import { MyOrdersResponse, Orders, OrderTypesEnum, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './history.style.scss';
import { get } from 'scripts/api';
import { API } from 'data/nfs.api';
import { OrderTypes } from 'data/orderTypes';
import { Button, Input, NFSModal } from 'utilities/components';
import HistorySkeleton from './skeleton/history.skeleton';
import OrderCard from './orderCard/orderCard.index';
import Pagination from '@mui/material/Pagination';
import HistoryDetail from './detail/historyDetail.index';

const History: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const [filter, setFilter] = useState<OrderTypesEnum>();
    const [searchTerm, setSearchTerm] = useState<string>();
    const ordersParams = useRef<any>({});
    const [page, setPage] = useState<number>(1);
    const [data, setData] = useState<Orders[] | undefined>();
    const [totalPage, setTotalPage] = useState<number>();
    const [totalCount, setTotalCount] = useState<number>();
    const [orderDetail, setOrderDetail] = useState<Orders>();
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        getData(value);
    };
    function getData(page: number) {
        setData(undefined);
        get<MyOrdersResponse>(API.order.mineOrders(page, 12), ordersParams.current)
            .then((e) => {
                const value = e.value.value;
                setData(value.items);
                setTotalPage(value.pagination.totalPages);
                setTotalCount(value.pagination.totalCount);
            })
            .catch((e) => console.log(e));
    }

    function searchHandler() {
        if (searchTerm && searchTerm !== '') ordersParams.current.Keyword = searchTerm;
        else if (ordersParams.current.Keyword) delete ordersParams.current.Keyword;
        getData(page);
    }
    useEffect(() => {
        getData(1);
    }, []);
    return (
        <div className="nfs-history">
            <NFSModal
                title={props.language.history.orderDetailsTitle}
                status={!!orderDetail}
                onClose={() => setOrderDetail(undefined)}
            >
                {orderDetail && <HistoryDetail onClose={() => setOrderDetail(undefined)} data={orderDetail} />}
            </NFSModal>
            <div className="header">
                <div className="filter">
                    {OrderTypes(props.language).map((item, index) => (
                        <label
                            onClick={() => {
                                if (filter === item.value) {
                                    setFilter(undefined);
                                    if (ordersParams.current.Type) delete ordersParams.current.Type;
                                } else {
                                    setFilter(item.value);
                                    ordersParams.current.Type = item.value;
                                }
                                getData(page);
                            }}
                            className={`filter-item ${filter === item.value ? 'active' : ''}`}
                            key={index}
                        >
                            <div className="content">
                                <label>{item.title}</label>
                            </div>
                            <div className="underline" />
                        </label>
                    ))}
                </div>
                <span className="spacer" />
                <div className="search-container">
                    <Input
                        onEnter={searchHandler}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e)}
                        className="search-input"
                        placeholder={props.language.history.searchPlaceholder}
                    />
                    <Button onClick={searchHandler} className="search-btn">
                        {props.language.history.searchBtn}
                    </Button>
                </div>
            </div>
            <div className="data-container">
                {data ? (
                    data.length !== 0 ? (
                        <div className="grid-container">
                            {data.map((item, index) => (
                                <OrderCard onDetailClick={() => setOrderDetail(item)} key={index} data={item} />
                            ))}
                            <div className="safe-grid" />
                            <div className="safe-grid" />
                        </div>
                    ) : (
                        <p className="empty-state">{props.language.history.emptyState}</p>
                    )
                ) : (
                    <div className="grid-container">
                        {Array.from({ length: 6 }, (_, index) => (
                            <HistorySkeleton key={index} />
                        ))}
                        <div className="safe-grid" />
                        <div className="safe-grid" />
                    </div>
                )}
            </div>
            {data && data.length !== 0 && totalCount && totalPage && (
                <div className="pagination">
                    <p>
                        {props.language.history.paginationDescription(
                            (page - 1) * 12 + 1,
                            page !== totalPage ? page * 12 : totalCount,
                            totalCount,
                        )}
                    </p>
                    <span className="spacer" />
                    <div className="pagination-container">
                        <Pagination
                            siblingCount={1}
                            color="standard"
                            page={page}
                            onChange={handleChange}
                            count={totalPage}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(History);

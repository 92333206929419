import { forgotLang } from './forgot/forgot.lang';
import { loginLang } from './login/login.lang';
import { registerLang } from './register/register.lang';

export const authenticationLang = {
    register: registerLang,
    login: loginLang,
    forgot: forgotLang,
    back: 'بازگشت',
    forgotPasswordTitle: 'فراموشی رمز عبور',
    haveAccount: 'حساب کاربری دارم',
    notHaveAccount: 'حساب کاربری ندارم',
};

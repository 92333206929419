import React, { useEffect, useState } from 'react';
import { Language, NfsCoins, QrPageWallete, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './qrPage.style.scss';
import { __CoinsSelected } from '../calculation.interface';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { getCoin } from 'scripts/getCoin';
import { QRCode } from 'react-qrcode-logo';
import { LuCopy } from 'react-icons/lu';
import copyToClipboard from 'scripts/copyToClipboard';
import { toast } from 'react-toastify';
import { post } from 'scripts/api';
import { API } from 'data/nfs.api';

const QrPage: React.FC<
    ConnectedProps<typeof connector> & {
        data: __CoinsSelected;
        onBackClick: () => void;
    }
> = function (
    props: ConnectedProps<typeof connector> & {
        data: __CoinsSelected;
        onBackClick: () => void;
    },
) {
    const [selectedSrcCoin, setSelectedSrcCoin] = useState<NfsCoins>();
    const language = props.totalLanguage.landing.calculation.qrPage;
    const [wallet, setWallet] = useState<string>();

    useEffect(() => {
        post<QrPageWallete>(API.qrPage, {})
            .then((e) => setWallet(e.value.value.walletAddress))
            .catch((e) => console.log(e));
        if (props.data.src) setSelectedSrcCoin(getCoin([props.data.src?.key], props.totalLanguage, props.coinsRate)[0]);
    }, [props.data.src, props.totalLanguage]);

    if (!selectedSrcCoin) return <></>;
    return (
        <div className="nfs-calculation-qr">
            <div className="header">
                <div onClick={() => props.onBackClick()} className="back">
                    <p>{language.back}</p>
                    {props.totalLanguage.code === Language.fa ? <IoMdArrowBack /> : <IoMdArrowForward />}
                </div>
                <label>{`${language.title} ${selectedSrcCoin.title}`}</label>
            </div>
            <div className="content">
                <p>{language.description(selectedSrcCoin)}</p>
                {wallet ? (
                    <>
                        <div className="qr-container">
                            <div className="qr-code">
                                <QRCode logoImage={selectedSrcCoin.icon} value={wallet} />
                            </div>
                            <h6>{wallet}</h6>
                        </div>
                        <div
                            onClick={() => {
                                toast.success(language.copiedSuccessfully);
                                copyToClipboard(wallet);
                            }}
                            className="copy-btn"
                        >
                            <p>{language.copy}</p>
                            <LuCopy />
                        </div>
                    </>
                ) : (
                    <div className="skeleton">
                        <div className="qr nfs-skeleton" />
                        <div className="address nfs-skeleton" />
                        <div className="btn nfs-skeleton" />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    totalLanguage: state.language,
    user: state.userData,
    coinsRate: state.coinsRate,
});

const connector = connect(mapStateToProps);
export default connector(QrPage);

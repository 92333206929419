import { AuthStatus, Language, UserData } from 'interface';
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { get } from 'scripts/api';
import { authToken, defaultLang } from 'scripts/storage';
import { NFSLanguage } from './nfs.lang';
import { API } from 'data/nfs.api';
import { setAuth, setUserData } from 'redux/actions';

export const nfsInitialize = (dispatch: Dispatch<AnyAction>, lang: typeof NFSLanguage) => {
    defaultLang.set(Language.fa);
    return new Promise((res) => getUser(dispatch, lang).then(() => res(true)));
};

const getUser = (dispatch: Dispatch<AnyAction>, lang: typeof NFSLanguage) => {
    return new Promise((resolve) => {
        if (!authToken.get()) {
            dispatch(setAuth(AuthStatus.inValid));
            resolve(true);
        } else {
            get<UserData>(API.profile)
                .then((res) => {
                    dispatch(setUserData(res.value.value));
                    dispatch(setAuth(AuthStatus.valid));
                })
                .catch(({ status }) => {
                    if (status >= 500) toast.error(lang.general.error);
                    else if (status >= 400) {
                        authToken.remove();
                        dispatch(setAuth(AuthStatus.inValid));
                    } else toast.error(lang.general.error);
                })
                .finally(() => resolve(true));
        }
    });
};

import { CSSProperties, ReactNode } from 'react';

export interface __ButtonProps {
    onClick?: (e: React.MouseEvent<any, MouseEvent>) => Promise<any | undefined> | void;
    type?: __ButtonTypes;
    style?: CSSProperties;
    disabled?: boolean;
    href?: string;
    className?: string;
    children?: ReactNode;
    onMouseUp?: () => void;
    onMouseDown?: (e: React.MouseEvent<any, MouseEvent>) => void;
    download?: boolean;
    target?: string;
    linkOut?: boolean;
    title?: string;
}

export enum __ButtonTypes {
    primary = 'primary',
    secondary = 'secondary',
    text = 'text',
    outline = 'outline',
}

export interface __ButtonRef {
    startLoading: () => void;
    stopLoading: () => void;
}

import { qrPageLang } from './qr/qrPage.lang';
import { QuickConvertLang } from './quickConvert/quickConvert.lang';
import { voucherPageLang } from './voucherPage/voucherPage.lang';
import { resultPageLang } from "./voucherPage/resultPage/resultPage.lang";

export const calculationLang = {
    quickConvert: QuickConvertLang,
    qrPage: qrPageLang,
    voucherPage: voucherPageLang,
    resultPage: resultPageLang
};

import { NFSLanguage } from 'nfs/nfs.lang';
import { PiHouseBold, PiBookOpenTextBold } from 'react-icons/pi';
import { IoMdTime } from 'react-icons/io';
import { RiStarSmileLine } from 'react-icons/ri';
import { NFSPath } from 'data/nfs.path';

export const headerNavbarItems = (language: typeof NFSLanguage) => {
    return [
        { icon: PiHouseBold, text: language.header.items.landing, loginRequired: false, key: '/' },
        { icon: IoMdTime, text: language.header.items.history, loginRequired: true, key: NFSPath.history },
        // { icon: PiWalletBold, text: language.header.items.exchange, key: '#exchange' },
        { icon: RiStarSmileLine, text: language.header.items.telegramBot, loginRequired: false, key: '#bot' },
        { icon: PiBookOpenTextBold, text: language.header.items.teachBuying, loginRequired: false, key: '#teaching' },
    ];
};

import { AuthStatus, CoinsRate, Direction, ReduxAction, UserData } from 'interface';

const USER_DATA = 'USER_DATA';
const setUserData = (payload: UserData | null): ReduxAction<UserData | null> => ({ type: USER_DATA, payload: payload });
export { USER_DATA, setUserData };

const SYSTEM_LANG = 'SYSTEM_LANG';
const setLanguage = <T>(payload: T): ReduxAction<T> => ({ type: SYSTEM_LANG, payload: payload });
export { SYSTEM_LANG, setLanguage };

const APP_DIRECTION = 'APP_DIRECTION';
const setDirection = (payload: Direction): ReduxAction<Direction> => ({ type: APP_DIRECTION, payload: payload });
export { APP_DIRECTION, setDirection };

const AUTH_STATUS = 'AUTH_STATUS';
const setAuth = (payload: AuthStatus): ReduxAction<AuthStatus> => ({ type: AUTH_STATUS, payload: payload });
export { AUTH_STATUS, setAuth };

const COINS_RATES = 'COINS_RATES';
const setCoinsRate = (payload: CoinsRate[]): ReduxAction<CoinsRate[]> => ({ type: COINS_RATES, payload: payload });
export { COINS_RATES, setCoinsRate };

const HEADER_LOGIN = 'HEADER_LOGIN';
const setHeaderLogin = (payload: boolean): ReduxAction<boolean> => ({ type: HEADER_LOGIN, payload: payload });
export { HEADER_LOGIN, setHeaderLogin };

export const historyDetailLang = {
    txidAddress: 'آدرس TXID',
    systemFee: 'کارمزد سیستم',
    fee: 'کارمزد',
    back: 'برگشت به سوابق',
    voucherCode: 'کد ووچر',
    voucherActivationCode: 'کد فعالساز ووچر',
    batchNumber: 'بچ نامبر',
    trackingCode: 'کد پیگیری',
};

import { NFScoins } from 'data/coins';
import { Coins, CoinsRate } from 'interface';
import { NFSLanguage } from 'nfs/nfs.lang';

export function getCoin(wantedCoins: Coins[], language: typeof NFSLanguage, rates: CoinsRate[]) {
    const result = wantedCoins.map((wantedCoin) => {
        const foundedCoin = NFScoins(language).find((item: any) => item.key === Coins[wantedCoin])!;
        const rate = rates.find((item) => item.symbol === foundedCoin?.key)?.rate;

        return { ...foundedCoin, rate };
    });
    return result;
}

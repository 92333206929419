import React, { useRef, useState } from 'react';
import { ReduxState, Tokens } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './email.style.scss';
import { Button, Input } from 'utilities/components';
import { post } from 'scripts/api';
import { API } from 'data/nfs.api';
import { emailValidation } from 'scripts/validations';
import { InputRef } from 'utilities/components/input/input.index';
import { ButtonRef } from 'utilities/components/button/button.index';

const ForgotPasswordEmail: React.FC<ConnectedProps<typeof connector> & { onFinish: (email: string) => void }> =
    function (props: ConnectedProps<typeof connector> & { onFinish: (email: string) => void }) {
        const _language = props.language.authentication.forgot.emailForgot;
        const [email, setEmail] = useState<string>();
        const emailRef = useRef<InputRef>(null);
        const buttonRef = useRef<ButtonRef>(null);

        function handleSubmit(isEnter: boolean) {
            let isNoError = true;
            if (email && !emailValidation(email)) {
                emailRef.current?.setError(_language.emailError);
                isNoError = false;
            }
            if (email && isNoError) {
                if (isEnter) buttonRef.current?.startLoading();
                return new Promise((resolve) => {
                    post<Tokens>(API.authentication.forgotPassword.forgotPassword, { email })
                        .then((res) => {
                            props.onFinish(email);
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => {
                            resolve(true);
                            if (isEnter) buttonRef.current?.stopLoading();
                        });
                });
            }
        }
        return (
            <div className="nfs-forgot-email">
                <p>{_language.description}</p>
                <Input
                    ref={emailRef}
                    onChange={(e) => setEmail(e)}
                    value={email}
                    type="email"
                    label={_language.email}
                    placeholder={_language.email}
                    onEnter={() => handleSubmit(true)}
                />
                <Button ref={buttonRef} onClick={() => handleSubmit(false)} disabled={!email}>
                    {_language.btn}
                </Button>
            </div>
        );
    };

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(ForgotPasswordEmail);

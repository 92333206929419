import React, { useEffect, useState } from 'react';
import { Coins, CoinsRate, NfsCoins, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './quickConvert.style.scss';
import { FiInfo } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';
import { AiOutlineSwap } from 'react-icons/ai';
import { NFSModal, Button } from 'utilities/components';
import CoinRow from './coinRow/coinRow.index';
import { getCoin } from 'scripts/getCoin';
import { get } from 'scripts/api';
import { API } from 'data/nfs.api';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { __CoinsSelected } from '../calculation.interface';
import { setCoinsRate } from 'redux/actions';

const CalculationQuickConvert: React.FC<
    ConnectedProps<typeof connector> & {
        onSubmit: (e: __CoinsSelected) => void;
    }
> = function (
    props: ConnectedProps<typeof connector> & {
        onSubmit: (e: __CoinsSelected) => void;
    },
) {
    const [isOpenModal, setIsOpenModal] = useState<'src' | 'dest' | null>(null);
    const [rates, setRates] = useState<CoinsRate[]>();
    const [src, setSrc] = useState<NfsCoins>();
    const [dest, setDest] = useState<NfsCoins>();
    const [srcValue, setSourceValue] = useState<number>();
    const [destValue, setDestValue] = useState<number>();
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (src?.rate && srcValue) setDestValue(srcValue * src?.rate);
    }, [src?.key, dest?.key, srcValue]);

    useEffect(() => {
        get<CoinsRate[]>(API.rate)
            .then((res) => {
                const _rates = res.value.value.map((item) => {
                    return { symbol: item.symbol, rate: item.rate };
                });
                setRates(_rates);
                props.dispatch(setCoinsRate(_rates));
                const _src = getCoin([Coins.TRX], props.totalLanguage, _rates)[0];
                const _dest = getCoin([Coins.PM], props.totalLanguage, _rates)[0];
                const INITIAL_SRC_VALUE = 1;
                setSrc(_src);
                setSourceValue(_src.rate ? INITIAL_SRC_VALUE / _src.rate : undefined);
                setDest(_dest);
                setDestValue(INITIAL_SRC_VALUE);
            })
            .catch(() => {
                toast.error(props.totalLanguage.general.error);
            });
    }, []);

    useEffect(() => {
        if (rates && !!src && !!dest) {
            setSrc(getCoin([src.key], props.totalLanguage, rates)[0]);
            setDest(getCoin([dest.key], props.totalLanguage, rates)[0]);
        }
    }, [props.totalLanguage]);

    function swap() {
        if (src?.key === Coins.USDT) return;
        const temp = src;
        setSrc(dest);
        setDest(temp);
    }

    function selectNewCoin(item: NfsCoins) {
        if (!rates) return;
        setIsOpenModal(null);

        // Check if the modal is for selecting a source coin
        if (isOpenModal === 'src') {
            // If the selected item is not the same as the destination, set it as the new source
            if (dest?.key !== item.key) {
                setSrc(item);
                if (item.key === Coins.USDT) {
                    const newDest = getCoin([Coins.PM], props.totalLanguage, rates)[0];
                    setDest(newDest);
                }

                return; // Exit early since we handled the selection
            }

            // Handle the case where the selected item is the same as the destination
            if (src?.key === Coins.USDT) {
                setSrc(item);
                const newDest =
                    item.key === Coins.PM
                        ? getCoin([Coins.TRX], props.totalLanguage, rates)[0]
                        : getCoin([Coins.PM], props.totalLanguage, rates)[0];
                setDest(newDest);
            } else {
                // If the source is the same and not USDT, just swap
                swap();
            }
        } else {
            // The modal is for selecting a destination coin
            if (src?.key !== item.key) {
                setDest(item);
            } else {
                // If the destination is the same, just swap
                swap();
            }
        }
    }

    if (!rates)
        return (
            <div className="nfs-calculation-loading">
                <PulseLoader className="icon" />
            </div>
        );

    const formatValue = (value?: number) => {
        if (value) return value % 1 === 0 ? value.toString() : value.toFixed(3).replace(/\.?0+$/, '');
        return;
    };

    function getSelectableCoins() {
        let res: Coins[] = [];
        switch (isOpenModal) {
            case 'src':
                res = [Coins.PM, Coins.TRX, Coins.USDT];
                break;
            case 'dest':
                res = [Coins.PM];
                if (src?.key === Coins.PM) res.push(Coins.TRX);
        }
        return res;
    }

    return (
        <div className="nfs-calculation-quick-convert">
            <NFSModal
                title={props.language.chooseCryptoTitle}
                status={!!isOpenModal}
                onClose={() => setIsOpenModal(null)}
            >
                {getCoin(getSelectableCoins(), props.totalLanguage, rates).map(
                    (item, index) => item && <CoinRow onSelect={() => selectNewCoin(item)} data={item} key={index} />,
                )}
            </NFSModal>
            <div className="title">
                <h4>{props.language.quickConvert}</h4>
                <Tooltip className="tooltip desktop" placement="top" arrow title={props.language.quickConvertTip}>
                    <div>
                        <FiInfo className="icon" />
                    </div>
                </Tooltip>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                        <Tooltip
                            className="tooltip mobile"
                            placement="top"
                            arrow
                            title={props.language.quickConvertTip}
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                        >
                            <div onClick={handleTooltipOpen}>
                                <FiInfo className="icon" />
                            </div>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
            </div>
            <div className="boxes-container">
                <div className="box">
                    <label>{props.language.yourPayment}</label>
                    <div className="value">
                        <input
                            type="number"
                            value={formatValue(srcValue)}
                            onChange={(e) => {
                                setSourceValue(parseFloat(e.target.value));
                                if (dest?.rate && src?.rate) setDestValue(parseFloat(e.target.value) * src?.rate);
                            }}
                        />
                        <span className="spacer" />
                        <div
                            onClick={() => {
                                setIsOpenModal('src');
                            }}
                            className="coin"
                        >
                            <IoIosArrowDown />
                            <p>{src?.title}</p>
                            <img src={src?.icon} alt={src?.key} />
                        </div>
                    </div>
                </div>
                <div onClick={swap} className={`${src?.key === Coins.USDT ? 'disabled' : ''} swap-icon`}>
                    <AiOutlineSwap />
                </div>
                <div className="box">
                    <label>{props.language.yourPayment}</label>
                    <div className="value">
                        <input
                            type="number"
                            onChange={(e) => {
                                setDestValue(parseFloat(e.target.value));
                                if (dest?.rate && src?.rate) setSourceValue(parseFloat(e.target.value) / src?.rate);
                            }}
                            value={formatValue(destValue)}
                        />
                        <span className="spacer" />
                        <div
                            onClick={() => {
                                setIsOpenModal('dest');
                            }}
                            className="coin"
                        >
                            <IoIosArrowDown />
                            <p>{dest?.title}</p>
                            <img src={dest?.icon} alt={dest?.key} />
                        </div>
                    </div>
                    <div className="fee">
                        <p>
                            {props.language.fee}: {props.totalLanguage.digit(1)}٪
                        </p>
                    </div>
                </div>
                <Button
                    onClick={() => src && dest && props.onSubmit({ src, dest, srcValue, destValue })}
                    className="btn"
                >
                    {props.language.convert} {src?.title} {props.language.to} {dest?.title}
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language.landing.calculation.quickConvert,
    totalLanguage: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(CalculationQuickConvert);

import React from 'react';
import { Coins, Language, Orders, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './orderCard.style.scss';
import { Button } from 'utilities/components';
import { ButtonTypes } from 'utilities/components/button/button.index';
import { NFScoins } from 'data/coins';
import moment from 'jalali-moment';

const OrderCard: React.FC<ConnectedProps<typeof connector> & { data: Orders; onDetailClick: () => void }> = function (
    props: ConnectedProps<typeof connector> & { data: Orders; onDetailClick: () => void },
) {
    const _language = props.language.history.orderCard;
    function getIcon(coin: Coins) {
        return NFScoins(props.language).find((item) => item.key === coin)?.icon;
    }
    return (
        <div className="nfs-history-order-card">
            <div className="header">
                <div className="content">
                    <div className="name">
                        <div className="title">
                            <label className="item">{_language.title}</label>
                            <label className="item">
                                {props.data.sourceMarket}/{props.data.destinationMarket}
                            </label>
                        </div>
                        <div className={`icon ${props.language.code === Language.fa ? 'fa' : 'en'}`}>
                            <div className="item">
                                <img src={getIcon(props.data.sourceMarket)} alt={props.data.sourceMarket} />
                            </div>
                            <div className="item">
                                <img src={getIcon(props.data.destinationMarket)} alt={props.data.destinationMarket} />
                            </div>
                        </div>
                    </div>
                    <div className="spacer" />
                    <div
                        className={`badge ${props.data.state.id === 0 ? 'waiting' : props.data.state.id === 1 ? 'doing' : 'done'}`}
                    >
                        {props.data.state.id === 0
                            ? _language.waitingForConfirm
                            : props.data.state.id === 1
                              ? _language.doing
                              : _language.done}
                    </div>
                </div>
                <div className="line" />
            </div>
            <div className="body-content">
                <div className="row">
                    <div className="item">
                        <p>{_language.sourceAmount}</p>
                        <p>{props.data.sourceAmount}</p>
                    </div>
                    <div className="item">
                        <p>{_language.destinationAmount}</p>
                        <p>{props.data.destinationAmount}</p>
                    </div>
                    <div className="item">
                        <p>{_language.trackingId}</p>
                        <p>{props.data.trackingCode}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="item">
                        <p>{_language.orderDate}</p>
                        <p>
                            {props.language.code === Language.fa
                                ? moment(props.data.createdOn)
                                      .locale('fa')
                                      .format('HH:mm jYYYY/jM/jD')
                                      .split('')
                                      .map((item) => props.language.digit(item))
                                : moment(props.data.createdOn).locale('en').format('dddd, D MMMM YYYY, hh:mm A')}
                        </p>
                    </div>
                    <Button onClick={() => props.onDetailClick()} type={ButtonTypes.outline} className="button ">
                        {_language.details}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(OrderCard);

export const landingStepsLang = {
    stepsTitle: 'مراحل تبدیل در نید فور سواپ',
    stepsDescription:
        'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، ',
    stepOneTitle: 'لورم ایپسوم',
    stepOneDescription:
        'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، ',
    stepTwoTitle: 'لورم ایپسوم',
    stepTwoDescription:
        'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، ',
    stepThreeTitle: 'لورم ایپسوم',
    stepThreeDescription:
        'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، ',
};

import React, { useState, useEffect } from 'react';
import { AuthStatus, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './navbar.style.scss';
import { headerNavbarItems } from './navbar.data';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { setHeaderLogin } from 'redux/actions';

const HeaderNavbar: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const history = useHistory();
    const location = useLocation();
    const [hash, setHash] = useState(location.hash);
    const [pathName, setPathName] = useState(location.pathname);

    // Update the hash when the location changes
    useEffect(() => {
        setPathName(location.pathname);
        setHash(location.hash);
    }, [history, location]);

    return (
        <div className="nfs-header-navbar">
            {headerNavbarItems(props.language).map((item, index) =>
                item.key.includes('#') ? (
                    pathName !== '/' ? (
                        <Link key={index} to={`/${item.key}`}>
                            <div className="content">
                                <item.icon />
                                <label>{item.text}</label>
                            </div>
                            <div className="underline" />
                        </Link>
                    ) : (
                        <a
                            // className={hash === item.key ? 'active' : ''}
                            key={index}
                            href={`${item.key}`}
                        >
                            <div className="content">
                                <item.icon />
                                <label>{item.text}</label>
                            </div>
                            <div className="underline" />
                        </a>
                    )
                ) : (
                    <Link
                        onClick={() => {
                            if (item.loginRequired && props.isAuth !== AuthStatus.valid)
                                props.dispatch(setHeaderLogin(true));
                        }}
                        to={item.key}
                        className={pathName === item.key ? 'active' : ''}
                        key={index}
                    >
                        <div className="content">
                            <item.icon />
                            <label>{item.text}</label>
                        </div>
                        <div className="underline" />
                    </Link>
                ),
            )}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(HeaderNavbar);

export const registerLang = {
    title: 'ثبت نام',
    email: 'ایمیل ',
    password: 'رمز عبور',
    confirmPassword: 'تایید رمز عبور',
    rules: 'من قوانین و مقررات نید فور سواپ را می‌پذیرم.',
    btn: 'ثبت نام',
    description: 'جهت ثبت نام ایمیل و رمز عبور خود را وارد نمایید',
    emailError: 'ایمیل وارد شده دارای فرمت اشتباهی است',
    passwordError: 'گذرواژه ورودی باید حداقل ۸ حرف شامل اعداد و حداقل یک حرف انگلیسی باشد',
    repeatPasswordError: 'رمز عبور و تایید رمز عبور یکسان نمی‌باشد',
    registerSucceed: 'ثبت نام با موفقیت انجام شد',
};

import { AuthStatus, Direction, ReduxAction, ReduxState } from 'interface';
import { APP_DIRECTION, AUTH_STATUS, COINS_RATES, HEADER_LOGIN, SYSTEM_LANG, USER_DATA } from './actions';
import { NFSLanguage } from 'nfs/nfs.lang';

const minDate = new Date();
minDate.setDate(minDate.getDate() - 30);
minDate.setHours(0, 0, 0, 0);
const maxData = new Date();
maxData.setDate(maxData.getDate() + 1);
maxData.setHours(0, 0, 0, 0);

export const initialState: ReduxState = {
    language: NFSLanguage,
    direction: Direction.rtl,
    authStatus: AuthStatus.pending,
    userData: null,
    coinsRate: [],
    headerLogin: false,
};

function reducer(state: ReduxState = initialState, action: ReduxAction<any>): ReduxState {
    switch (action.type) {
        case USER_DATA:
            return { ...state, userData: action.payload };
        case AUTH_STATUS:
            return { ...state, authStatus: action.payload };
        case SYSTEM_LANG:
            return { ...state, language: action.payload };
        case APP_DIRECTION:
            return { ...state, direction: action.payload };
        case COINS_RATES:
            return { ...state, coinsRate: action.payload };
        case HEADER_LOGIN:
            return { ...state, headerLogin: action.payload };
        default:
            return state;
    }
}

export default reducer;

import React from 'react';
import './modal.style.scss';
import { __ModalProps } from './modal.interface';
import Backdrop from '@mui/material/Backdrop';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const NFSModal: React.FC<__ModalProps> = function (props: __ModalProps) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={`nfs-modal ${props.className ? props.className : ''}`}
            open={props.status}
            onClose={props.onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={props.status}>
                <div className="nfs-modal-container">
                    <div className="nfs-modal-container-title">
                        <h4>{props.title}</h4>
                        <span className="spacer" />
                        <IoIosCloseCircleOutline className="icon" onClick={props.onClose} />
                    </div>
                    <div style={props.style} className="nfs-modal-body">
                        {props.children}
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default NFSModal;

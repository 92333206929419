import React, { useEffect, useRef, useState } from 'react';
import './input.style.scss';
import { __InputProps, __InputRef } from './input.interface';
import { ClipLoader } from 'react-spinners';
export interface InputRef extends __InputRef {}

const Input: React.ForwardRefRenderFunction<__InputRef, __InputProps> = (props: __InputProps, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.defaultValue ? props.defaultValue : '');
    const [loading, setLoading] = useState<boolean>(false);

    React.useImperativeHandle(ref, () => ({
        getValue: () => (inputRef.current ? inputRef.current.value : ''),
        getValidation: () => Validation(),
        setValue: (e: string) => setValue(e),
        setError: (text) => {
            setError(text);
        },
        setUnError: () => setError(undefined),
        focus: () => inputRef.current?.focus(),
    }));

    useEffect(() => {
        setValue(props.value ? props.value : '');
    }, [props.value]);
    useEffect(() => {
        setDisabled(props.disabled ? true : false);
    }, [props.disabled]);
    function onFocusHandler() {
        if (!props.readOnly) setActive(true);
    }
    function onblurHandler(e: any) {
        setActive(false);

        if (props.onBlur) {
            props.onBlur(e.target.value);
            const returned = props.onBlur();
            if (returned) {
                setLoading(true);
                returned.then(() => {
                    setLoading(false);
                });
            }
        }
    }
    function onClickHandler() {
        inputRef.current?.focus();
    }
    function onChangeHandler(e: any) {
        setError(undefined);
        setValue(e.target.value);
        if (props.onChange) props.onChange(e.target.value);
    }
    function Validation() {
        let validate = true;
        if (value)
            if (
                (props.length && value.length !== props.length) ||
                (props.maxLength && value.length > props.maxLength) ||
                (props.minLength && value.length < props.minLength)
            )
                validate = false;
        return validate;
    }
    function onKeyUpHandler(e: any) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (props.onEnter) props.onEnter(value);
        }
    }

    return (
        <div
            onClick={onClickHandler}
            className={`nfs-input ${value && value.length ? 'filled' : ''} ${active ? 'active' : ''} ${
                error ? 'error' : ''
            } ${disabled || loading ? ' disabled' : ''} ${props.className ? props.className : ''}`}
        >
            {props.label && <label>{props.label}</label>}
            {props.hint && <p className="input-hint">{props.hint}</p>}
            <div>
                {props.startIcon && <props.startIcon />}
                <input
                    autoFocus={props.autoFocus}
                    onKeyPress={onKeyUpHandler}
                    ref={inputRef}
                    type={props.type}
                    value={value}
                    disabled={disabled || loading}
                    placeholder={props.placeholder}
                    onFocus={onFocusHandler}
                    onBlur={onblurHandler}
                    onChange={onChangeHandler}
                    autoComplete={props.autoComplete}
                    readOnly={props.readOnly}
                />
                {props.end &&
                    (!loading ? (
                        <div
                            className={`end-icon ${!!props.end.onClick ? 'active' : ''}`}
                            onClick={() => {
                                if (props.end?.onClick) props.end.onClick(value);
                            }}
                        >
                            {props.end.text && <label>{props.end.text}</label>}
                            {props.end.icon && <props.end.icon className="icon" />}
                        </div>
                    ) : (
                        <ClipLoader />
                    ))}
            </div>
            {error && <p className="input-error">{error}</p>}
        </div>
    );
};

export default React.forwardRef(Input);

export const orderCardLang = {
    title: 'جفت ارز',
    sourceAmount: 'مقدار مبدا',
    destinationAmount: 'مقدار مقصد',
    trackingId: 'شناسه',
    orderType: 'نوع سفارش',
    convert: 'تبدیل',
    orderDate: 'زمان و تاریخ سفارش',
    waitingForConfirm: 'در انتظار تایید',
    doing: 'درحال انجام',
    done: 'انجام شده',
    details: 'جزئیات',
};

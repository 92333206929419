import { emailForgotLang } from './email/email.lang';
import { otpForgotLang } from './otp/otp.lang';
import { setNewPasswordLang } from './setNewPassword/setNewPassword.lang';

export const forgotLang = {
    title: 'فراموشی رمز عبور',
    emailForgot: emailForgotLang,
    otpForgot: otpForgotLang,
    setNewPassword: setNewPasswordLang,
};

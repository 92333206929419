import React from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './steps.style.scss';

const LandingSteps: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    return (
        <div className="nfs-landing-steps">
            <h4>{props.language.landing.steps.stepsTitle}</h4>
            <p>{props.language.landing.steps.stepsDescription}</p>
            <div className="steps-container">
                <div className="item">
                    <div className="shape">
                        <div className="square one">
                            <div className="square two">
                                <div className="square three">
                                    <label>{props.language.digit(1)}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text">
                        <h6>{props.language.landing.steps.stepOneTitle}</h6>
                        <p>{props.language.landing.steps.stepOneDescription}</p>
                    </div>
                </div>
                <div className="line" />
                <div className="item">
                    <div className="shape">
                        <div className="square one">
                            <div className="square two">
                                <div className="square three">
                                    <label>{props.language.digit(2)}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text">
                        <h6>{props.language.landing.steps.stepTwoTitle}</h6>
                        <p>{props.language.landing.steps.stepTwoDescription}</p>
                    </div>
                </div>
                <div className="line" />
                <div className="item">
                    <div className="shape">
                        <div className="square one">
                            <div className="square two">
                                <div className="square three">
                                    <label>{props.language.digit(3)}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text">
                        <h6>{props.language.landing.steps.stepThreeTitle}</h6>
                        <p>{props.language.landing.steps.stepThreeDescription}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(LandingSteps);

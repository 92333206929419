import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './button.style.scss';
import { __ButtonProps, __ButtonRef, __ButtonTypes } from './button.interface';
import { PulseLoader } from 'react-spinners';
export const ButtonTypes = __ButtonTypes;
export interface ButtonRef extends __ButtonRef {}

const Button: React.ForwardRefRenderFunction<__ButtonRef, __ButtonProps> = (props: __ButtonProps, ref) => {
    const [loading, setLoading] = useState<boolean>(false);
    React.useImperativeHandle(ref, () => ({
        startLoading: () => setLoading(true),
        stopLoading: () => setLoading(false),
    }));

    const providedProps = {
        style: props.style,
        className: `nfs-button ${props.className} ${loading ? 'button-loading' : ''} ${
            props.type ? props.type : __ButtonTypes.primary
        } ${props.disabled ? 'disabled' : ''}`,
        onClick: onClickHandler,
        onMouseDown: props.onMouseDown,
        onMouseUp: props.onMouseUp,
        title: props.title,
    };

    function onClickHandler(e: any) {
        if (props.disabled) {
            e.preventDefault();
        } else if (props.onClick) {
            const returned = props.onClick(e);
            if (returned) {
                setLoading(true);
                returned.then(() => {
                    setLoading(false);
                });
            }
        }
    }

    function content() {
        return (
            <>
                {props.children}
                {loading && (
                    <span className="button-loader">
                        <PulseLoader color="#ffffff" size={8} />
                    </span>
                )}
            </>
        );
    }
    if (props.href && !props.linkOut) {
        return (
            <Link download={props.download} {...providedProps} target={props.target} to={props.href}>
                {content()}
            </Link>
        );
    } else if (props.href && props.linkOut) {
        return (
            <a download={props.download} {...providedProps} target={props.target} href={props.href}>
                {content()}
            </a>
        );
    }
    return <button {...providedProps}>{content()}</button>;
};

export default React.forwardRef(Button);

import React, { useEffect, useRef, useState } from 'react';
import {
    AddWalletBody,
    Language,
    ReduxState,
    SavedWallet,
    SwapPmWithCryptoBody,
    SwapPmWithCryptoResponse,
} from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './voucherPage.style.scss';
import { __CoinsSelected } from '../calculation.interface';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { Button, Input, NFSModal } from 'utilities/components';
import { PiWalletBold } from 'react-icons/pi';
import { FiPlus } from 'react-icons/fi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatWalletAddress } from '../../../scripts/formatWalletAddress';
import { get, post } from '../../../scripts/api';
import { API } from '../../../data/nfs.api';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { trxValidation } from '../../../scripts/validations';
import { InputRef } from '../../../utilities/components/input/input.index';
import ResultPage from './resultPage/resultPage.index';

const VoucherPage: React.FC<
    ConnectedProps<typeof connector> & {
        data: __CoinsSelected;
        onBackClick: () => void;
    }
> = function (
    props: ConnectedProps<typeof connector> & {
        data: __CoinsSelected;
        onBackClick: () => void;
    },
) {
    const language = props.totalLanguage.landing.calculation.voucherPage;
    const [resultData, setResultData] = useState<SwapPmWithCryptoResponse>();
    const [body, setBody] = useState<SwapPmWithCryptoBody>({
        voucherCode: '',
        voucherActivationCode: '',
        wallet: '',
    });
    const [savedWallets, setSavedWallets] = useState<SavedWallet[]>();
    const [savedWalletsLoading, setSavedWalletsLoading] = useState<boolean>(false);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [addWalletBody, setAddWalletBody] = useState<AddWalletBody>({ walletAddress: '', label: '' });
    const walletRef = useRef<InputRef>(null);

    const handleInputChange = (field: keyof SwapPmWithCryptoBody) => (value: string) => {
        setBody({ ...body, [field]: value });
    };

    const handleAddWalletInputChange = (field: keyof AddWalletBody) => (value: string) => {
        setAddWalletBody({ ...addWalletBody, [field]: value });
    };

    const onAddWallet = () => {
        return new Promise((resolve) => {
            post(API.userSavedWallet.add, { label: addWalletBody.label, walletAddress: body.wallet })
                .then((response) => {
                    resolve(true);
                    setAddWalletBody({ label: '', walletAddress: '' });
                    toast.success(language.addWalletModal.successToast);
                    setIsModalOpen(false);
                    getMineWallets();
                })
                .catch((error) => console.error(error.data.message))
                .finally(() => resolve(true));
        });
    };

    const handlePlusClick = () => {
        let isNoError = true;
        if (body.wallet && !trxValidation(body.wallet)) {
            walletRef.current?.setError(language.regexError);
            isNoError = false;
        }
        if (isNoError) setIsModalOpen(true);
    };

    const onSwapVoucherToTron = () => {
        return new Promise((resolve) => {
            post(API.order.swapPmWithCrypto, body)
                .then((response) => {
                    resolve(true);
                    setBody({ voucherCode: '', voucherActivationCode: '', wallet: '' });
                    setResultData(response.value.value as SwapPmWithCryptoResponse);
                })
                .catch((error) => console.error(error.data.message))
                .finally(() => resolve(true));
        });
    };

    const getMineWallets = () => {
        setSavedWalletsLoading(true);
        get<SavedWallet[]>(API.userSavedWallet.getMine).then((response) => {
            setSavedWalletsLoading(false);
            setSavedWallets(response.value.value);
        });
    };

    useEffect(() => {
        getMineWallets();
    }, []);

    if (!props.data) return <></>;
    if (resultData) return <ResultPage data={resultData} />;
    return (
        <div className="nfs-calculation-voucher">
            <NFSModal
                className={'add-wallet-modal'}
                title={language.addWalletModal.title}
                status={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setAddWalletBody({ label: '', walletAddress: '' });
                }}
            >
                <Input
                    value={addWalletBody.label}
                    placeholder={language.addWalletModal.inputs.label}
                    onChange={handleAddWalletInputChange('label')}
                />
                <Button onClick={onAddWallet}>{language.addWalletModal.button}</Button>
            </NFSModal>
            <div className="header">
                <label>{language.title}</label>
                <div onClick={() => props.onBackClick()} className="back">
                    <p>{language.back}</p>
                    {props.totalLanguage.code === Language.fa ? <IoMdArrowBack /> : <IoMdArrowForward />}
                </div>
            </div>
            <div className="content">
                <p>{language.description}</p>
                <Input
                    className="voucher-input"
                    value={body?.voucherCode}
                    onChange={handleInputChange('voucherCode')}
                    placeholder={language.inputs.voucher}
                    maxLength={20}
                />
                <Input
                    className="voucher-input"
                    value={body?.voucherActivationCode}
                    onChange={handleInputChange('voucherActivationCode')}
                    placeholder={language.inputs.voucherActivation}
                    maxLength={20}
                />
                <Input
                    className="voucher-input"
                    value={body?.wallet}
                    onChange={handleInputChange('wallet')}
                    placeholder={language.inputs.wallet}
                    maxLength={42}
                    ref={walletRef}
                />
                <div className={`add-wallet ${!body.wallet ? 'disabled' : ''}`} onClick={handlePlusClick}>
                    <div className="plus">
                        <FiPlus className="icon" />
                    </div>
                    <label>{language.addToWallet}</label>
                </div>
            </div>
            <label className={'saved-wallets-title'}>{language.savedWallets}</label>
            {savedWalletsLoading ? (
                <PulseLoader className="pulse-loader-icon" />
            ) : savedWallets?.length === 0 ? (
                <div className="empty-state">{language.empty}</div>
            ) : (
                <>
                    <Swiper
                        className={'saved-wallets-swiper'}
                        breakpoints={{
                            992: {
                                slidesPerView: 2.5,
                            },
                            0: {
                                slidesPerView: 2.25,
                            },
                        }}
                        spaceBetween={16}
                    >
                        {savedWallets?.map((item, index) => (
                            <SwiperSlide
                                key={index}
                                className={'saved-wallet'}
                                onClick={() => setBody({ ...body, wallet: item.walletAddress })}
                            >
                                <div className={'icon-container'}>
                                    <PiWalletBold />
                                </div>
                                <div className={'wallet-info'}>
                                    <span className={'label'}>{item.label}</span>
                                    <span className={'address'}>{formatWalletAddress(item.walletAddress)}</span>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <ul className="saved-wallets-list">
                        {savedWallets?.map((item, index) => (
                            <li
                                className={'saved-wallet'}
                                key={index}
                                onClick={() => setBody({ ...body, wallet: item.walletAddress })}
                            >
                                <div className={'icon-container'}>
                                    <PiWalletBold />
                                </div>
                                <div className={'wallet-info'}>
                                    <span className={'label'}>{item.label}</span>
                                    <span className={'address'}>{formatWalletAddress(item.walletAddress)}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </>
            )}
            <Button
                className={'submit-button'}
                onClick={onSwapVoucherToTron}
                disabled={!body.voucherCode || !body.voucherActivationCode || !body.wallet}
            >
                {language.button}
            </Button>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    totalLanguage: state.language,
    user: state.userData,
    coinsRate: state.coinsRate,
});

const connector = connect(mapStateToProps);
export default connector(VoucherPage);

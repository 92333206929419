import React, { useEffect } from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './landing.style.scss';
import LandingSteps from './steps/steps.index';
import Button, { ButtonTypes } from 'utilities/components/button/button.index';
import { CiPlay1 } from 'react-icons/ci';
import LandingSlider from './slider/slider.index';
import { RiTokenSwapLine } from 'react-icons/ri';
import { IoMdTime } from 'react-icons/io';
import { MdOutlineMonitorHeart } from 'react-icons/md';
import LandingImageOne from 'assets/images/landing/landingSupport.png';
import LandingRobot from 'assets/images/landing/landingRobot.png';
import Calculation from 'components/calculation/calculation.index';
import { useLocation } from 'react-router-dom';

const Landing: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const location = useLocation();
    useEffect(() => {
        if (location.hash.includes('#')) {
            window.location.href = location.hash;
        }
    }, []);
    return (
        <div className="nfs-landing">
            <div className="calculation-container">
                <Calculation />
            </div>
            <div className="calculation-container fake left"></div>
            <div className="calculation-container fake right"></div>
            <LandingSteps />
            <div id="teaching" className="teaching">
                <div className="container">
                    <h4>{props.language.teachingTitle}</h4>
                    <p>{props.language.teachingDescription}</p>
                    <Button className="watching-btn" type={ButtonTypes.secondary}>
                        {props.language.watchingTeaching}
                        <CiPlay1 />
                    </Button>
                </div>
            </div>
            <LandingSlider />
            <div className="features">
                <h4>{props.language.features.title}</h4>
                <p>{props.language.features.description}</p>
                <div className="items-container">
                    <div className="item">
                        <div className="icon">
                            <RiTokenSwapLine className="icon-item" />
                        </div>
                        <h6>{props.language.features.oneTitle}</h6>
                        <p>{props.language.features.oneDescription}</p>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <IoMdTime className="icon-item" />
                        </div>
                        <h6>{props.language.features.twoTitle}</h6>
                        <p>{props.language.features.twoDescription}</p>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <MdOutlineMonitorHeart className="icon-item" />
                        </div>
                        <h6>{props.language.features.threeTitle}</h6>
                        <p>{props.language.features.threeDescription}</p>
                    </div>
                </div>
            </div>
            <div className="info-graph">
                <img src={LandingImageOne} alt="nfs-2" />
                <div className="text">
                    <h4>{props.language.support.title}</h4>
                    <p>{props.language.support.description}</p>
                    <Button className="info-graph-btn">{props.language.support.button}</Button>
                </div>
            </div>
            <div id="bot" className="info-graph reverse">
                <img src={LandingRobot} alt="nfs-3" />
                <div className="text">
                    <h4>{props.language.telegramBot.title}</h4>
                    <p>{props.language.telegramBot.description}</p>
                    <Button className="info-graph-btn">{props.language.telegramBot.button}</Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language.landing,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(Landing);

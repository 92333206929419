import { Coins } from 'interface';
import PMLogo from 'assets/images/Coins/PM.png';
import TRXLogo from 'assets/images/Coins/TRX.png';
import USDTLogo from 'assets/images/Coins/USDT.png';
import { NFSLanguage } from 'nfs/nfs.lang';

export const NFScoins = (language: typeof NFSLanguage) => {
    return [
        {
            title: language.coins.perfectMoney,
            description: language.coins.voucher,
            key: Coins.PM,
            icon: PMLogo,
        },
        {
            key: Coins.TRX,
            title: language.coins.tron,
            description: language.coins.trx,
            icon: TRXLogo,
        },
        {
            title: language.coins.tether,
            description: language.coins.usdt,
            key: Coins.USDT,
            icon: USDTLogo,
        },
    ];
};

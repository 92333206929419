export const setNewPasswordLang = {
    title: 'تغییر رمز عبور',
    password: 'رمز عبور',
    confirmPassword: 'تایید رمز عبور',
    btn: 'انجام',
    description: 'لطفا پسورد جدید خود را وارد نمایید',
    passwordError: 'گذرواژه ورودی باید حداقل ۸ حرف شامل اعداد و حداقل یک حرف انگلیسی باشد',
    repeatPasswordError: 'رمز عبور و تایید رمز عبور یکسان نمی‌باشد',
    resetPasswordSucceed: 'تغییر رمز عبور با موفقیت انجام شد',
};

import { buildTimeValue } from '@testing-library/user-event/dist/utils';

export const loginLang = {
    title: 'ورود',
    email: 'ایمیل ',
    password: 'رمز عبور',
    btn: 'ورود',
    description: 'جهت ورود ایمیل و رمز عبور خود را وارد نمایید',
    emailError: 'ایمیل وارد شده دارای فرمت اشتباهی است',
    passwordError: 'گذرواژه با فرمت اشتباهی وارد شده است',
    loginSucceed: 'عملیات ورود با موفقیت انجام شد',
    loginError: 'نام کاربری یا رمز عبور اشتباه می‌باشد',
};

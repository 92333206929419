import React, { useState } from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './forgot.style.scss';
import ForgotPasswordEmail from './email/email.index';
import OTPPage from './otp/otp.index';
import SetNewPassword from './setNewPassword/setNewPassword.index';

const ForgotPassword: React.FC<ConnectedProps<typeof connector> & { onFinish: () => void }> = function (
    props: ConnectedProps<typeof connector> & { onFinish: () => void },
) {
    const [stage, setStage] = useState<'email' | 'otp' | 'setNewPassword'>('email');
    const [enteredEmail, setEnteredEmail] = useState<string>();
    return (
        <div className="nfs-forgot">
            {stage === 'email' && (
                <ForgotPasswordEmail
                    onFinish={(email) => {
                        setStage('otp');
                        setEnteredEmail(email);
                    }}
                />
            )}
            {enteredEmail && (
                <>
                    {stage === 'otp' && (
                        <OTPPage
                            changeEmail={() => setStage('email')}
                            email={enteredEmail}
                            onFinish={() => setStage('setNewPassword')}
                        />
                    )}
                    {stage === 'setNewPassword' && <SetNewPassword onFinish={() => props.onFinish()} />}
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(ForgotPassword);

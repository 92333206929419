import React from 'react';
import './coinRow.style.scss';
import { NfsCoins } from 'interface';

const CoinRow: React.FC<{ data: NfsCoins; onSelect: () => void }> = function (props: {
    data: NfsCoins;
    onSelect: () => void;
}) {
    return (
        <div onClick={() => props.onSelect()} className="nfs-calculation-quick-convert-coin-row">
            <img src={props.data.icon} alt={props.data.key} />
            <div className="title">
                <h6>{props.data.title}</h6>
                <p>{props.data.description}</p>
            </div>
        </div>
    );
};

export default CoinRow;

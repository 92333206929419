import { Coins, NfsCoins } from 'interface';

export const qrPageLang = {
    back: 'بازگشت',
    title: 'واریز ارز ',
    copy: 'کپی آدرس',
    copiedSuccessfully: 'آدرس مورد نظر با موفقیت کپی شد',
    description: (coin: NfsCoins) =>
        `هر مقدار ${coin.title} در شبکه TRX به آدرس زیر منتقل شود به صورت اتوماتیک برای شما به ووچر پرفکت مانی تبدیل خواهد شد.`,
};

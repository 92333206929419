import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthStatus, Direction, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import { nfsInitialize } from './nfs.scripts';
import Landing from './landing/landing.index';
import Header from 'components/header/header.index';
import Footer from 'components/footer/footer.index';
import { NFSPath } from 'data/nfs.path';
import History from './history/history.index';
import './nfs.style.scss';

const NFS: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    useEffect(() => {
        nfsInitialize(props.dispatch, props.language);
    }, []);

    return (
        <Router>
            <ToastContainer
                position={props.direction === Direction.ltr ? 'bottom-right' : 'bottom-left'}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={props.direction === Direction.rtl}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Header />
            <div className="nfs-body">
                <Switch>
                    {props.isAuth === AuthStatus.valid && <Route path={NFSPath.history} component={History} />}
                    <Route path={'*'} component={Landing} />
                </Switch>
            </div>
            <Footer />
        </Router>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(NFS);

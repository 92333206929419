import React, { useEffect, useState } from 'react';
import { AuthStatus, Coins, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './calculation.style.scss';
import CalculationQuickConvert from './quickConvert/quickConvert.index';
import QrPage from './qr/qrPage.index';
import { __CoinsSelected } from './calculation.interface';
import VoucherPage from './voucherPage/voucherPage.index';
import Authentication from 'components/authentication/authentication.index';
import { setHeaderLogin } from 'redux/actions';
import { PulseLoader } from 'react-spinners';

const Calculation: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const [stage, setStage] = useState<'calculation' | 'qrPage' | 'voucherPage' | 'authentication'>('calculation');
    const [coinsSelectedData, setCoinsSelectedData] = useState<__CoinsSelected>();
    useEffect(() => {
        if (props.headerLogin) setStage('authentication');
    }, [props.headerLogin]);

    useEffect(() => {
        if (stage !== 'authentication') props.dispatch(setHeaderLogin(false));
        else if (stage === 'authentication' && props.isAuth === AuthStatus.valid) setStage('calculation');
    }, [stage]);

    function coinsSelectHandler(e: __CoinsSelected) {
        setCoinsSelectedData(e);
        if ((e.src?.key === Coins.TRX || e.src?.key === Coins.USDT) && e.dest?.key === Coins.PM) setStage('qrPage');
        else if (e.src?.key === Coins.PM && e.dest?.key === Coins.TRX) setStage('voucherPage');
    }
    return (
        <div className="nfs-calculation">
            {stage === 'calculation' && <CalculationQuickConvert onSubmit={coinsSelectHandler} />}
            {stage === 'authentication' && (
                <Authentication onFinish={() => setStage('calculation')} onBackClick={() => setStage('calculation')} />
            )}
            {(stage === 'voucherPage' || stage === 'qrPage') &&
                coinsSelectedData &&
                (props.isAuth === AuthStatus.valid ? (
                    <>
                        {stage === 'qrPage' && (
                            <QrPage onBackClick={() => setStage('calculation')} data={coinsSelectedData} />
                        )}
                        {stage === 'voucherPage' && (
                            <VoucherPage onBackClick={() => setStage('calculation')} data={coinsSelectedData} />
                        )}
                    </>
                ) : props.isAuth === AuthStatus.inValid ? (
                    <Authentication onBackClick={() => setStage('calculation')} />
                ) : (
                    <PulseLoader className="calculation-page-loading" />
                ))}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
    headerLogin: state.headerLogin,
});

const connector = connect(mapStateToProps);
export default connector(Calculation);

import React from 'react';
import { Coins, Language, Orders, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './historyDetail.style.scss';
import { NFScoins } from 'data/coins';
import moment from 'jalali-moment';
import { Button } from 'utilities/components';
import { ButtonTypes } from 'utilities/components/button/button.index';
const HistoryDetail: React.FC<ConnectedProps<typeof connector> & { data: Orders; onClose: () => void }> = function (
    props: ConnectedProps<typeof connector> & { data: Orders; onClose: () => void },
) {
    function getIcon(coin: Coins) {
        return NFScoins(props.language).find((item) => item.key === coin)?.icon;
    }
    return (
        <div className="nfs-history-detail">
            <div className="general">
                <div className={`icon ${props.language.code === Language.fa ? 'fa' : 'en'}`}>
                    <div className="item">
                        <img src={getIcon(props.data.sourceMarket)} alt={props.data.sourceMarket} />
                    </div>
                    <div className="item">
                        <img src={getIcon(props.data.destinationMarket)} alt={props.data.destinationMarket} />
                    </div>
                </div>
                <div className="prices">
                    <h2>{`- ${props.data.sourceAmount} ${props.data.sourceMarket}`}</h2>
                    <h2>{`+ ${props.data.destinationAmount} ${props.data.destinationMarket}`}</h2>
                </div>
                <p>{`$${props.data.sourceDollarPrice}`}</p>
                <p>{`${props.language.landing.calculation.resultPage.date} ${
                    props.language.code === Language.fa
                        ? moment(props.data.createdOn).locale('fa').format('dddd jD jMMMM jYYYY، ساعت HH:mm')
                        : moment(props.data.createdOn).locale('en').format('dddd, D MMMM YYYY, hh:mm A')
                }`}</p>
            </div>
            <div className="info-box">
                {props.data.trackingCode && (
                    <div className="item">
                        <label>{props.language.history.historyDetail.trackingCode}</label>
                        <p>{props.data.trackingCode}</p>
                    </div>
                )}
                {props.data.txId && (
                    <div className="item">
                        <label>{props.language.history.historyDetail.txidAddress}</label>
                        <p>{props.data.txId}</p>
                    </div>
                )}
                {props.data.voucherCode && (
                    <div className="item">
                        <label>{props.language.history.historyDetail.voucherCode}</label>
                        <p>{props.data.voucherCode}</p>
                    </div>
                )}
                {props.data.voucherActivationCode && (
                    <div className="item">
                        <label>{props.language.history.historyDetail.voucherActivationCode}</label>
                        <p>{props.data.voucherActivationCode}</p>
                    </div>
                )}
                {props.data.batchNumber && (
                    <div className="item">
                        <label>{props.language.history.historyDetail.batchNumber}</label>
                        <p>{props.data.batchNumber}</p>
                    </div>
                )}
                {props.data.commission && (
                    <div className="item">
                        <label>{props.language.history.historyDetail.fee}</label>
                        <p>${props.data.commission}</p>
                    </div>
                )}
                {props.data.networkFee && (
                    <div className="item">
                        <label>{props.language.history.historyDetail.systemFee}</label>
                        <p>${props.data.networkFee}</p>
                    </div>
                )}
            </div>
            <Button className="back-btn" onClick={() => props.onClose()} type={ButtonTypes.secondary}>
                {props.language.history.historyDetail.back}
            </Button>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(HistoryDetail);

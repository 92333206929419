import { Language, Tokens } from 'interface';

export const authToken = {
    key: 'NFS__AUTH_TOKEN',
    get: function (): Tokens | null {
        const data = localStorage.getItem(authToken.key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    set: (value: Tokens) => {
        localStorage.setItem(authToken.key, JSON.stringify(value));
    },
    remove: () => {
        localStorage.removeItem(authToken.key);
    },
};

export const defaultLang = {
    key: 'NFS_LANG',
    get: function (): Language | null {
        const view = localStorage.getItem(defaultLang.key);
        if (view === undefined || view === null || isNaN(parseInt(view))) return null;
        else {
            if (view === Language.en.toString()) return Language.en;
            else return Language.fa;
        }
    },
    set: (value: Language) => {
        localStorage.setItem(defaultLang.key, value.toString());
    },
    remove: () => {
        localStorage.removeItem(defaultLang.key);
    },
};

import ENV from 'env.json';

export const API = {
    rate: ENV.api + '/Rate/CalculateRates',
    authentication: {
        register: ENV.api + '/User/Register',
        signin: ENV.api + '/User/Signin',
        forgotPassword: {
            setPassword: ENV.api + '/User/SetPassword',
            forgotPassword: ENV.api + '/User/ForgetPassword',
            verifyForgotPassword: ENV.api + '/User/VerifyForgetPassword',
        },
    },
    qrPage: ENV.api + '/User/CreateWallet',
    userSavedWallet: {
        getMine: ENV.api + '/UserSavedWallet/GetMineWallets',
        add: ENV.api + '/UserSavedWallet/Add',
    },
    order: {
        swapPmWithCrypto: ENV.api + '/Order/SwapPmWithCrypto',
        mineOrders: (page: number, pageSize: number) => `${ENV.api}/Order/GetMineOrders/${page}/${pageSize}`,
    },
    profile: ENV.api + '/User/GetMineProfile',
};

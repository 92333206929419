import { historyDetailLang } from './detail/historyDetail.lang';
import { orderCardLang } from './orderCard/orderCard.lang';

export const historyLang = {
    filters: {
        pmToCrypto: 'پرفکت مانی به کریپتو',
        cryptoToPM: 'کریپتو به پرفکت مانی',
    },
    searchPlaceholder: 'شماره تراکنش خود را وارد کنید',
    searchBtn: 'جستجو',
    emptyState: 'داده‌ای برای نمایش موجود نمی‌باشد',
    orderDetailsTitle: 'جزئیات تراکنش',
    paginationDescription: (from: number, to: number, total: number) =>
        `نمایش تراکنش ${from} تا ${to} از ${total} تراکنش`,
    orderCard: orderCardLang,
    historyDetail: historyDetailLang,
};

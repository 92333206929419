import React from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './history.styleton.scss';

const HistorySkeleton: React.FC<ConnectedProps<typeof connector>> = function () {
    return (
        <div className="nfs-history-skeleton">
            <div className="header">
                <div className="content">
                    <div className="name">
                        <div className="title">
                            <div className="item nfs-skeleton"></div>
                            <div className="item nfs-skeleton"></div>
                        </div>
                        <div className="icon">
                            <div className="item">
                                <div className="nfs-skeleton" />
                            </div>
                            <div className="item">
                                <div className="nfs-skeleton" />
                            </div>
                        </div>
                    </div>
                    <div className="spacer" />
                    <div className="button nfs-skeleton" />
                </div>
                <div className="line" />
            </div>
            <div className="content">
                <div className="column">
                    <div className="item">
                        <div className="nfs-skeleton" />
                        <div className="nfs-skeleton" />
                    </div>
                    <div className="item">
                        <div className="nfs-skeleton" />
                        <div className="nfs-skeleton" />
                    </div>
                </div>
                <div className="column">
                    <div className="item">
                        <div className="nfs-skeleton" />
                        <div className="nfs-skeleton" />
                    </div>
                    <div className="item">
                        <div className="nfs-skeleton" />
                        <div className="nfs-skeleton" />
                    </div>
                </div>
                <div className="column">
                    <div className="item">
                        <div className="nfs-skeleton" />
                        <div className="nfs-skeleton" />
                    </div>
                    <div className="button nfs-skeleton" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(HistorySkeleton);

import React from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './footer.style.scss';
import Logo from 'assets/images/nfslogo.png';
import { Link, useHistory } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi';
import { FaInstagram, FaTelegram, FaYoutube } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { NFSPath } from 'data/nfs.path';

const Footer: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const history = useHistory();
    return (
        <div className="nfs-footer">
            <div className="container">
                <div className="top">
                    <div className="container">
                        <div className="info">
                            <img onClick={() => history.push(NFSPath.landing)} src={Logo} alt="nfs-logo" />
                            <p>{props.language.description}</p>
                            <p>{props.language.copyRight}</p>
                        </div>
                        <div className="links">
                            <div className="item">
                                <label>{props.language.listTitleOne}</label>
                                <Link to="#">{props.language.listOneItemOne}</Link>
                                <Link to="#">{props.language.listOneItemTwo}</Link>
                                <Link to="#">{props.language.listOneItemThree}</Link>
                                <Link to="#">{props.language.listOneItemFour}</Link>
                            </div>
                            <div className="item">
                                <label>{props.language.listTitleTwo}</label>
                                <Link to="#">{props.language.listTwoItemOne}</Link>
                                <Link to="#">{props.language.listTwoItemTwo}</Link>
                                <Link to="#">{props.language.listTwoItemThree}</Link>
                                <Link to="#">{props.language.listTwoItemFour}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="container">
                        <label>{props.language.contactUsTitle}</label>
                        <label>{props.language.contactUsDescription}</label>
                        <a href="mailto:Needforswap@gmail.com" className="email">
                            <HiOutlineMail className="icon" />
                            <label>Needforswap@gmail.com</label>
                        </a>
                        <div className="socials">
                            <Link target="_blank" to="#">
                                <FaSquareXTwitter />
                            </Link>
                            <Link target="_blank" to="#">
                                <FaInstagram />
                            </Link>
                            <Link target="_blank" to="#">
                                <FaYoutube />
                            </Link>
                            <Link target="_blank" to="#">
                                <FaTelegram />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language.footer,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(Footer);

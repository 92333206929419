import React, { useRef, useState } from 'react';
import { AuthStatus, ReduxState, Tokens, UserData } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './register.style.scss';
import { Button, Input } from 'utilities/components';
import Checkbox from 'utilities/components/checkbox/checkbox.index';
import { get, post } from 'scripts/api';
import { API } from 'data/nfs.api';
import { toast } from 'react-toastify';
import { emailValidation, passwordValidation } from 'scripts/validations';
import { InputRef } from 'utilities/components/input/input.index';
import { authToken } from 'scripts/storage';
import { setAuth, setUserData } from 'redux/actions';
import { ButtonRef } from 'utilities/components/button/button.index';

const Register: React.FC<ConnectedProps<typeof connector> & { onFinish: () => void }> = function (
    props: ConnectedProps<typeof connector> & { onFinish: () => void },
) {
    const _language = props.language.authentication.register;
    const [isApprovedRules, setIsApprovedRules] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();
    const [password, setPssword] = useState<string>();
    const [repeatPassword, setRepeatPassword] = useState<string>();
    const emailRef = useRef<InputRef>(null);
    const passwordRef = useRef<InputRef>(null);
    const repeatPasswordRef = useRef<InputRef>(null);
    const buttonRef = useRef<ButtonRef>(null);

    function handleSubmit(isEnter: boolean) {
        let isNoError = true;
        if (email && !emailValidation(email)) {
            emailRef.current?.setError(_language.emailError);
            isNoError = false;
        }
        if (password && !passwordValidation(password)) {
            passwordRef.current?.setError(_language.passwordError);
            isNoError = false;
        }
        if (repeatPassword !== password) {
            repeatPasswordRef.current?.setError(_language.repeatPasswordError);
            isNoError = false;
        }
        if (isNoError) {
            if (isEnter) buttonRef.current?.startLoading();
            return new Promise((resolve) => {
                post<Tokens>(API.authentication.register, { email, password, repeatPassword })
                    .then((res) => {
                        authToken.set(res.value.value);
                        toast.success(_language.registerSucceed);
                        props.dispatch(setAuth(AuthStatus.valid));
                        get<UserData>(API.profile)
                            .then((res) => {
                                resolve(true);
                                if (isEnter) buttonRef.current?.stopLoading();
                                props.onFinish();
                                props.dispatch(setUserData(res.value.value));
                            })
                            .catch(() => toast.error(props.language.general.error));
                    })
                    .catch((err) => {
                        toast.error(props.language.general.error);
                        resolve(true);
                        if (isEnter) buttonRef.current?.stopLoading();
                    });
            });
        }
    }
    return (
        <div className="nfs-register">
            <p>{_language.description}</p>
            <Input
                ref={emailRef}
                onChange={(e) => setEmail(e)}
                value={email}
                type="email"
                label={_language.email}
                placeholder={_language.email}
            />
            <Input
                ref={passwordRef}
                onChange={(e) => setPssword(e)}
                value={password}
                type="password"
                label={_language.password}
                placeholder={_language.password}
            />
            <Input
                ref={repeatPasswordRef}
                onChange={(e) => setRepeatPassword(e)}
                value={repeatPassword}
                type="password"
                label={_language.confirmPassword}
                placeholder={_language.confirmPassword}
                onEnter={() => handleSubmit(true)}
            />
            <Checkbox onClick={(e) => setIsApprovedRules(e)}>{_language.rules}</Checkbox>
            <Button
                ref={buttonRef}
                onClick={() => handleSubmit(false)}
                disabled={!email || !password || !repeatPassword || !isApprovedRules}
            >
                {_language.btn}
            </Button>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(Register);

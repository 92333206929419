import React, { useState } from 'react';
import { Language, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './authentication.style.scss';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import Register from './register/register.index';
import Login from './login/login.index';
import ForgotPassword from './forgot/forgot.index';

const Authentication: React.FC<
    ConnectedProps<typeof connector> & {
        onBackClick: () => void;
        onFinish?: () => void;
    }
> = function (
    props: ConnectedProps<typeof connector> & {
        onBackClick: () => void;
        onFinish?: () => void;
    },
) {
    const [stage, setStage] = useState<'register' | 'login' | 'forgot'>('login');
    return (
        <div className="nfs-authentication">
            <div className="header">
                <label>{props.language.authentication[stage].title}</label>
                <div onClick={() => props.onBackClick()} className="back">
                    <p>{props.language.authentication.back}</p>
                    {props.language.code === Language.fa ? <IoMdArrowBack /> : <IoMdArrowForward />}
                </div>
            </div>
            <div className="content">
                {stage === 'register' ? (
                    <Register onFinish={() => props.onFinish && props.onFinish()} />
                ) : stage === 'login' ? (
                    <Login onFinish={() => props.onFinish && props.onFinish()} />
                ) : (
                    <ForgotPassword onFinish={() => props.onFinish && props.onFinish()} />
                )}
                <div className="options">
                    {stage !== 'forgot' && (
                        <label onClick={() => setStage('forgot')}>
                            {props.language.authentication.forgotPasswordTitle}
                        </label>
                    )}
                    {stage === 'register' ? (
                        <span onClick={() => setStage('login')}>{props.language.authentication.haveAccount}</span>
                    ) : (
                        stage === 'login' && (
                            <span onClick={() => setStage('register')}>
                                {props.language.authentication.notHaveAccount}
                            </span>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(Authentication);

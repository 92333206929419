import React, { useState } from 'react';
import './checkbox.style.scss';
import { __CheckboxProps } from './checkbox.interface';
import { RxCheck } from 'react-icons/rx';

const Checkbox: React.FC<__CheckboxProps> = function (props: __CheckboxProps) {
    const [isActive, setIsActive] = useState<boolean>(props.defaultValue ?? false);

    function onClickHandler(e: any) {
        if (props.disabled) {
            e.preventDefault();
        } else if (props.onClick) {
            setIsActive(!isActive);
            props.onClick(!isActive);
        }
    }

    return (
        <div className={`nfs-checkbox ${props.className} ${props.disabled ? 'disabled' : ''}`}>
            <span onClick={onClickHandler} className="checkmark">
                <div className={`fill ${isActive ? 'active' : ''}`}>
                    <RxCheck />
                </div>
            </span>
            <label>{props.children}</label>
        </div>
    );
};

export default Checkbox;

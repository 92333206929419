export const headerLang = {
    registerBtn: 'ثبت نام / ورود',
    accountBtn: 'حساب کاربری',
    yourEmail: 'ایمیل شما',
    logout: 'خروج',
    items: {
        landing: 'صفحه اصلی',
        history: 'تاریخچه',
        exchange: 'صرافی',
        telegramBot: 'ربات تلگرام',
        teachBuying: 'آموزش خرید',
    },
};

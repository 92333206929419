import React, { useEffect, useRef, useState } from 'react';
import { ReduxState, Tokens } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './otp.style.scss';
import { Button, NFSOTP } from 'utilities/components';
import { post } from 'scripts/api';
import { API } from 'data/nfs.api';
import { ButtonRef, ButtonTypes } from 'utilities/components/button/button.index';
import { authToken } from 'scripts/storage';
import { secondToTime } from 'scripts/time';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

const OTPPage: React.FC<
    ConnectedProps<typeof connector> & { onFinish: () => void; email: string; changeEmail: () => void }
> = function (
    props: ConnectedProps<typeof connector> & { onFinish: () => void; email: string; changeEmail: () => void },
) {
    const SEND_CODE_LIMIT = 120;
    const _language = props.language.authentication.forgot.otpForgot;
    const [otp, setOtp] = useState<string>('');
    const buttonRef = useRef<ButtonRef>(null);
    const [timeReminding, setTimeReminding] = useState(SEND_CODE_LIMIT);
    const [sentCodeAgainLoading, setSentCodeAgainLoading] = useState<boolean>(false);

    function counter(value: number) {
        setTimeReminding(value);
        if (value > 0) {
            setTimeReminding(value);
            setTimeout(() => {
                counter(value - 1);
            }, 1000);
        } else {
            setTimeReminding(SEND_CODE_LIMIT);
        }
    }
    useEffect(() => {
        counter(SEND_CODE_LIMIT - 1);
    }, []);

    useEffect(() => {
        if (otp && otp.length === 5) handleSubmit(true);
    }, [otp]);

    function handleSubmit(isEnter: boolean) {
        if (isEnter) buttonRef.current?.startLoading();
        return new Promise((resolve) => {
            post<Tokens>(API.authentication.forgotPassword.verifyForgotPassword, {
                otpCode: otp,
                email: props.email,
                forTelegram: false,
            })
                .then((res) => {
                    authToken.set(res.value.value);
                    props.onFinish();
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    resolve(true);
                    if (isEnter) buttonRef.current?.stopLoading();
                });
        });
    }
    function sendCodeAgain() {
        setSentCodeAgainLoading(true);
        return new Promise((resolve) => {
            post<Tokens>(API.authentication.forgotPassword.forgotPassword, { email: props.email })
                .then((res) => {
                    counter(SEND_CODE_LIMIT - 1);
                    toast.success(_language.sentAgainResult);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    resolve(true);
                    setSentCodeAgainLoading(false);
                });
        });
    }

    return (
        <div className="nfs-forgot-otp">
            <p>{_language.description}</p>
            <h6>{_language.OTPLabel}</h6>
            <div className="otp-container">
                <NFSOTP value={otp} onChange={setOtp} length={5} />
            </div>
            <div className="otp-options">
                <span onClick={() => props.changeEmail()}>{_language.changeEmail}</span>
                {timeReminding === SEND_CODE_LIMIT ? (
                    sentCodeAgainLoading ? (
                        <BeatLoader size={8} color="#ffffff" />
                    ) : (
                        <label className="decoration" onClick={sendCodeAgain}>
                            {_language.notCode}
                        </label>
                    )
                ) : (
                    <label>
                        {_language.sentAgainLabel}
                        {secondToTime(timeReminding)}
                    </label>
                )}
            </div>
            <Button ref={buttonRef} onClick={() => handleSubmit(false)} disabled={!otp}>
                {_language.btn}
            </Button>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(OTPPage);

import { OrderTypesEnum } from 'interface';
import { NFSLanguage } from 'nfs/nfs.lang';

export const OrderTypes = (language: typeof NFSLanguage) => {
    return [
        {
            title: language.history.filters.pmToCrypto,
            value: OrderTypesEnum.PmToCrypto,
        },
        {
            title: language.history.filters.cryptoToPM,
            value: OrderTypesEnum.CryptoToPm,
        },
    ];
};

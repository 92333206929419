import { Language, Direction } from 'interface';
import { NFSLanguage } from 'nfs/nfs.lang';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { setDirection, setLanguage } from 'redux/actions';
import { defaultLang } from './storage';

export default function changeLang(selected: Language, dispatch: Dispatch<AnyAction>) {
    const body = document.body;

    if (selected === Language.en) {
        body.dir = Direction.ltr;
        body.classList.remove('persian-lang'); // Remove the Persian font class
        body.classList.add('english-lang'); // Add the English font class
        dispatch(setDirection(Direction.ltr));
        import('i18n/En').then((text) => dispatch(setLanguage(text.EnText)));
        defaultLang.set(Language.en);
    } else if (selected === Language.fa) {
        body.dir = Direction.rtl;
        body.classList.remove('english-lang'); // Remove the English font class
        body.classList.add('persian-lang'); // Add the Persian font class
        dispatch(setDirection(Direction.rtl));
        dispatch(setLanguage(NFSLanguage));
        defaultLang.set(Language.fa);
    }
}

export interface __Tokens {
    accessToken: string;
    email: string;
    expiresIn: number;
    fullName: string;
    isAuthorized: boolean;
    isTwoFactorEnabled: boolean;
    refreshToken: string;
    roles: string[];
    tokenType: string;
}

export enum __AuthStatus {
    valid = 'valid',
    pending = 'pending',
    inValid = 'inValid',
}

export interface __UserData {
    createdOn: string;
    chatId: number;
    email: string;
    orderCount: number;
}

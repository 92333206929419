export const QuickConvertLang = {
    quickConvert: 'تبدیل سریع',
    yourPayment: 'پرداختی شما',
    quickConvertInfo: 'لورم لیپسوم',
    convert: 'تبدیل',
    to: 'به',
    chooseCryptoTitle: 'ارز مورد نظر خود را انتخاب کنید',
    fee: 'کارمزد',
    quickConvertTip: 'لورم ایپسوم',
};

import { calculationLang } from 'components/calculation/calculation.lang';
import { landingStepsLang } from './steps/steps.lang';

export const landingLang = {
    steps: landingStepsLang,
    teachingTitle: 'آموزش خرید از نید فور سواپ',
    teachingDescription:
        'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، ',
    watchingTeaching: 'مشاهده‌آموزش',
    features: {
        title: 'ویژگی‌های نید فور سواپ',
        description:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، ',
        oneTitle: 'لورم ایپسوم',
        oneDescription:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،  و با استفاده از طراحان گرافیک است، ',
        twoTitle: 'لورم ایپسوم',
        twoDescription:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،  و با استفاده از طراحان گرافیک است، ',
        threeTitle: 'لورم ایپسوم',
        threeDescription:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،  و با استفاده از طراحان گرافیک است، ',
    },
    support: {
        title: 'نید فور سواپ همیشه پشتیبان شماست',
        description:
            ', لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،  و با استفاده از طراحان گرافیک است،لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،  و با استفاده از طراحان گرافیک است،لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،  و با استفاده از طراحان گرافیک است، ',
        button: 'نیاز به کمک دارم!',
    },
    telegramBot: {
        title: 'ربات تلگرامی نید فور سواپ',
        description:
            ', لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،  و با استفاده از طراحان گرافیک است،لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،  و با استفاده از طراحان گرافیک است،لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،  و با استفاده از طراحان گرافیک است، ',
        button: 'ربات تلگرام',
    },
    calculation: calculationLang,
};

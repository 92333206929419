import React from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './slider.style.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Banner1 from 'assets/images/Banners/Banner1.png';
import Banner2 from 'assets/images/Banners/Banner2.png';
import Banner3 from 'assets/images/Banners/Banner3.png';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';

const LandingSlider: React.FC<ConnectedProps<typeof connector>> = function () {
    return (
        <div className="nfs-landing-slider">
            <Swiper
                loop={true}
                effect="coverflow"
                grabCursor={true}
                pagination={true}
                centeredSlides={true}
                slidesPerView={1.2}
                spaceBetween={90}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                }}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 400,
                    modifier: 1,
                    slideShadows: false,
                }}
                breakpoints={{
                    940: {
                        spaceBetween: 200,
                        slidesPerView: 1.9,
                    },
                    576: {
                        spaceBetween: 100,
                        slidesPerView: 1.9,
                    },
                }}
                modules={[EffectCoverflow, Autoplay, Pagination]}
                className="nfs-swiper"
            >
                <SwiperSlide>
                    <img src={Banner1} alt="nfs-1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Banner2} alt="nfs-1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Banner3} alt="nfs-1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Banner1} alt="nfs-1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Banner2} alt="nfs-1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Banner3} alt="nfs-1" />
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    language: state.language,
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(LandingSlider);

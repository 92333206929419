import React from 'react';
import { Language, ReduxState, SwapPmWithCryptoResponse } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './resultPage.style.scss';
import { FaCheck } from 'react-icons/fa';
import moment from 'jalali-moment';
import { Button } from '../../../../utilities/components';
import { ButtonTypes } from '../../../../utilities/components/button/button.index';
import { NFSPath } from '../../../../data/nfs.path';

const ResultPage: React.FC<
    ConnectedProps<typeof connector> & {
        data: SwapPmWithCryptoResponse;
    }
> = function (
    props: ConnectedProps<typeof connector> & {
        data: SwapPmWithCryptoResponse;
    },
) {
    const language = props.totalLanguage.landing.calculation.resultPage;
    const data = props.data;

    const formattedDate =
        props.totalLanguage.code === Language.fa
            ? moment(data.paidAt).locale('fa').format('dddd jD jMMMM jYYYY، ساعت HH:mm')
            : moment(data.paidAt).locale('en').format('dddd, D MMMM YYYY, hh:mm A');

    return (
        <div className="nfs-result-page">
            <div className={'icon-container'}>
                <FaCheck />
            </div>
            <h4 className={'result-title'}>{language.title}</h4>
            <p>
                {language.date}&nbsp;{formattedDate}
            </p>
            <div className={'content'}>
                <div className={'tx-id-container'}>
                    <label>{language.txId}:</label>
                    <span>{data.txid}</span>
                </div>
                <hr />
                <div className={'amount-container'}>
                    <label>{language.amount}:</label>
                    <div
                        className={'amounts'}
                        style={{ alignItems: props.totalLanguage.code === Language.en ? 'flex-end' : 'flex-start' }}
                    >
                        <span>{data.destinationAmount} TRX</span>
                        <span>${data.destinationAmountInDollar}</span>
                    </div>
                </div>
            </div>
            <Button href={NFSPath.history} type={ButtonTypes.secondary}>
                {language.buttonText}
            </Button>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    direction: state.direction,
    isAuth: state.authStatus,
    totalLanguage: state.language,
    user: state.userData,
    coinsRate: state.coinsRate,
});

const connector = connect(mapStateToProps);
export default connector(ResultPage);

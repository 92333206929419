import { Coins, Pagination } from 'interface';

export interface __FileAttachment {
    alt?: string;
    id: number;
    image: string;
    avatar: string;
    size?: string;
    name?: string;
    file?: string;
    video?: string;
    workspace?: number;
}

export interface __NfsCoins {
    title: string;
    key: __Coins;
    description: string;
    icon: string;
    rate?: number;
}

export interface __CoinsRate {
    symbol: __Coins;
    rate: number;
}

export interface __SavedWallet {
    walletAddress: string;
    network: string;
    label: string;
    createdOn: string;
}

export interface __AddWalletBody {
    walletAddress?: string;
    label?: string;
}

export interface __SwapPmWithCryptoBody {
    wallet?: string;
    voucherCode?: string;
    voucherActivationCode?: string;
}

export interface __SwapPmWithCryptoResponse {
    txid: string;
    sourceAmount: number;
    sourceMarket: string;
    destinationAmount: number;
    destinationAmountInDollar: number;
    paidAt: string;
}

export interface __Orders {
    txId: string;
    voucherCode: string;
    voucherActivationCode: string;
    state: {
        id: number;
        title: string;
    };
    sourceMarket: Coins;
    destinationMarket: Coins;
    trackingCode: string;
    paidAt: string;
    batchNumber: string;
    sourceAmount: number;
    destinationAmount: number;
    createdBy: string;
    createdOn: string;
    modifiedBy: string;
    lastModifiedDate: string;
    sourceAmountInDollar: number;
    sourceDollarPrice: number;
    commission: number;
    networkFee: number;
}

export interface __QrPageWallete {
    walletAddress: string;
    walletId: string;
    network: string;
}
export interface __MyOrdersResponse {
    items: __Orders[];
    pagination: Pagination;
}

export enum __TimeZone {
    Iran = 1,
    GMT = 2,
    America = 3,
}

export enum __Language {
    en = 1,
    fa,
}

export enum __TextDirection {
    ltr = 1,
    rtl = 2,
}

export enum __Coins {
    TRX = 'TRX',
    USDT = 'USDT',
    PM = 'PM',
}
export enum __OrderTypeEnum {
    PmToCrypto,
    CryptoToPm,
}
